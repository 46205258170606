import cn from 'classnames';
import { Button, LanguageList } from 'components';
import { LANGUAGES } from 'constants/locales';
import { AnimatePresence } from 'framer-motion';
import { EventCategories, useQuery, useTrackingEvent } from 'hooks';
import { useEffect, useState } from 'react';
// import { useIntl } from 'react-intl';
import { useTranslation } from 'react-i18next';
import { useSettingsStore } from 'store/settingsStore';
// import i18n from '../../../services/i18n';
import styles from './LanguageSelect.module.css';

interface LanguageSelectProps {
  isShadeFinder: boolean;
}

interface LanguageButtonProps {
  code: string;
  name: string | undefined;
}

export const LanguageSelect: React.FC<LanguageSelectProps> = ({
  isShadeFinder,
}) => {
  const { i18n } = useTranslation();
  const event = useTrackingEvent();
  const { isGucci, setCurrentLanguage } = useSettingsStore();
  const [languages, setLanguages] = useState<LanguageButtonProps[]>([]);
  const [selectIsOpen, setSelectIsOpen] = useState<boolean>(false);
  const [isShoppable, setIsShoppable] = useState<boolean>(false);

  const getLanguageName = (code: string) => {
    return LANGUAGES.find((language) => language.lang === code)?.name;
  };

  useEffect(() => {
    const languages = LANGUAGES.map((language) => {
      return {
        code: language.lang,
        name: language.name,
      };
    });

    setLanguages(languages);
  }, []);

  const onToggleSelect = () => {
    setSelectIsOpen(!selectIsOpen);
  };

  const onExitSelect = () => {
    setSelectIsOpen(false);
  };

  const query = useQuery();

  useEffect(() => {
    const shoppable = query.get('app');
    if (shoppable) setIsShoppable(true);
  }, [query]);

  return (
    <>
      <Button
        onClick={onToggleSelect}
        className={cn(styles.toggleButton, isShoppable && styles.isShoppable)}
      >
        <img
          src={`${process.env.PUBLIC_URL}/images/icons/globe.png`}
          alt=""
          className={styles.toggleButtonIcon}
        />
        <div className={styles.toggleButtonLabel}>
          {getLanguageName(i18n.language)}
        </div>
      </Button>
      <AnimatePresence>
        <LanguageList
          key="languages"
          languages={languages}
          selectIsOpen={selectIsOpen}
          onSelect={(code: string) => {
            event(
              isShadeFinder
                ? EventCategories.ShadeFinder
                : EventCategories.Beauty,
              'Language selector',
              code,
              isGucci
            );
            setSelectIsOpen(false);
            setCurrentLanguage(code);
          }}
          onExit={onExitSelect}
        />
      </AnimatePresence>
    </>
  );
};
