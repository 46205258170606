import { LANGUAGES } from 'constants/locales';
import { PAGE_ACTIONS, PageStateContext } from 'context/PageState';
import { ScriptStatus } from 'hooks/useScript';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ExperienceType } from 'types/shared';
import styles from './ExperienceModule.module.css';

interface ExperienceModuleProps {
  type: ExperienceType;
  script: ScriptStatus;
  setIsModuleLoaded: React.Dispatch<React.SetStateAction<boolean>>;
  setIsCameraDisabled: React.Dispatch<
    React.SetStateAction<boolean | undefined>
  >;
}

export const ExperienceModule: React.FC<ExperienceModuleProps> = ({
  type,
  script,
  setIsModuleLoaded,
  setIsCameraDisabled,
}) => {
  const { i18n } = useTranslation();
  const [moduleLanguage, setModuleLanguage] = useState<string>('');

  const { pageDispatch } = useContext(PageStateContext);

  useEffect(() => {
    const language = LANGUAGES.find((lang) => lang.lang === i18n.language);
    setModuleLanguage(language?.vtoLang || 'enu');
  }, [i18n.language]);

  useEffect(() => {
    const onScriptReady = () => {
      window.YMK.init({
        width: window.innerWidth,
        height: window.innerHeight,
        language: moduleLanguage,
        userConsent: 'agree',
        skinSmoothStrength: 1,
        controlButtonsBottomOffset: window.innerHeight / 2 - 50,
      });

      switch (type) {
        case 'MAKEUP':
        case 'LOOKS':
          window.YMK.open({ loadLiveMode: true });
          break;
        case 'NAILS':
          window.YMK.openHandAR({ loadLiveMode: true });
          break;
        case 'SHADE_FINDER':
          window.YMK.openShadeFinder();
          break;
        default:
          window.YMK.open({ loadLiveMode: true });
      }
    };

    if (script === 'ready' && moduleLanguage !== '') onScriptReady();
  }, [script, i18n.language, moduleLanguage, type]);

  useEffect(() => {
    const isCameraDisabled = window.YMK.addEventListener('cameraFailed', () => {
      setIsCameraDisabled(true);
    });

    return () => {
      window.YMK.removeEventListener(isCameraDisabled);
    };
  }, [setIsCameraDisabled]);

  useEffect(() => {
    const isModuleLoaded = window.YMK.addEventListener('loaded', () => {
      setIsModuleLoaded(true);
    });

    return () => {
      window.YMK.removeEventListener(isModuleLoaded);
    };
  }, [setIsModuleLoaded]);

  useEffect(() => {
    const isModuleClosed = window.YMK.addEventListener('closed', () => {
      pageDispatch({ type: PAGE_ACTIONS.MENU });
    });

    return () => {
      window.YMK.removeEventListener(isModuleClosed);
    };
  }, [pageDispatch]);

  return <div className={styles.wrapper} id="YMK-module"></div>;
};
