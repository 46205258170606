import { useEffect, useState } from 'react';

export const useDeviceId = () => {
  const [deviceId, setDeviceId] = useState<string>();

  useEffect(() => {
    const storedDeviceId = localStorage.getItem('deviceId');
    if (!storedDeviceId) {
      const newDeviceId = crypto.randomUUID();
      localStorage.setItem('deviceId', newDeviceId);
      setDeviceId(newDeviceId);
    } else {
      setDeviceId(storedDeviceId);
    }
  }, []);

  return deviceId;
};
