import { LanguageSelect, ShadeFinderMenu, TryOnMenu, View } from 'components';
import { VIDEO_ACTIONS, VideoStateContext } from 'context/VideoState';
import { useContext, useEffect } from 'react';
import { ExperienceType } from 'types/shared';

interface MenuProps {
  isShadeFinder: boolean;
  onLinkSelect: (type: ExperienceType) => void;
}

export const Menu: React.FC<MenuProps> = ({ isShadeFinder, onLinkSelect }) => {
  const { videoDispatch } = useContext(VideoStateContext);

  useEffect(() => {
    videoDispatch({ type: VIDEO_ACTIONS.MENU_OPEN });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onLinkSelected = (type: ExperienceType) => {
    onLinkSelect(type);
    videoDispatch({ type: VIDEO_ACTIONS.MENU_CLOSE });
  };

  return (
    <View>
      {isShadeFinder ? (
        <>
          <ShadeFinderMenu onLinkSelected={onLinkSelected} />
        </>
      ) : (
        <TryOnMenu onLinkSelected={onLinkSelected} />
      )}
      <LanguageSelect isShadeFinder={isShadeFinder} />
    </View>
  );
};
