import { ExperienceType } from 'types/shared';
import { create } from 'zustand';

export interface SettingsStore {
  isShoppable: boolean;
  isGucci: boolean;
  currentCountry: string;
  currentRegion: string;
  experienceType: ExperienceType;
  currentLanguage: string;
  setIsShoppable: (shoppable: boolean) => void;
  setIsGucci: (gucci: boolean) => void;
  setCountry: (country: string) => void;
  setRegion: (region: string) => void;
  setExperienceType: (experienceType: ExperienceType) => void;
  setCurrentLanguage: (language: string) => void;
}

export const useSettingsStore = create<SettingsStore>((set) => ({
  isShoppable: false,
  isGucci: false,
  currentCountry: '',
  currentRegion: '',
  experienceType: 'MAKEUP',
  currentLanguage: '',
  setIsShoppable: (shoppable) => set(() => ({ isShoppable: shoppable })),
  setIsGucci: (gucci) => set(() => ({ isGucci: gucci })),
  setCountry: (country) => set(() => ({ currentCountry: country })),
  setRegion: (region) => set(() => ({ currentRegion: region })),
  setExperienceType: (experienceType: ExperienceType) =>
    set(() => ({ experienceType: experienceType })),
  setCurrentLanguage: (language) => set(() => ({ currentLanguage: language })),
}));
