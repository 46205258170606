import cn from 'classnames';
import { Button } from 'components';
import { FADE, VARIANT_LABELS } from 'constants/animations';
import { PAGE_ACTIONS, PageStateContext } from 'context/PageState';
import { motion } from 'framer-motion';
import { EventCategories, useTrackingEvent } from 'hooks';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSettingsStore } from 'store/settingsStore';
import styles from './TermsOverlay.module.css';

export const TermsOverlay: React.FC = () => {
  const { pageDispatch } = useContext(PageStateContext);

  const [isAccepted, setIsAccepted] = useState(false);

  const { t } = useTranslation();
  const event = useTrackingEvent();
  const { isGucci } = useSettingsStore();

  const handleChecked = () => {
    setIsAccepted(!isAccepted);
  };

  const handleAccepted = () => {
    if (isAccepted) {
      event(
        EventCategories.ShadeFinder,
        'Clicks on Confirm Privacy Policy',
        '/',
        isGucci
      );
      pageDispatch({ type: PAGE_ACTIONS.EXPERIENCE });
    }
  };

  const handleExit = () => {
    pageDispatch({ type: PAGE_ACTIONS.MENU });
  };
  return (
    <motion.div
      key="terms"
      variants={FADE}
      {...VARIANT_LABELS}
      className={styles.wrapper}
    >
      <div className={styles.modal}>
        <Button onClick={handleExit} className={styles.exit}>
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M18 19.7071L0 1.70712L1.41421 0.292908L19.4142 18.2929L18 19.7071Z"
              fill="#999999"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M1.41431 19.7071L19.4143 1.70712L18.0001 0.292908L9.34601e-05 18.2929L1.41431 19.7071Z"
              fill="#999999"
            />
          </svg>
        </Button>
        <div className={styles.text}>
          <div
            dangerouslySetInnerHTML={{
              __html: t('messages.general_terms'),
            }}
            className={styles.textInner}
          />
        </div>
        <div>
          <label className={styles.checkbox}>
            <input
              type="checkbox"
              checked={isAccepted}
              onChange={handleChecked}
              className={styles.checkboxInput}
            />
            <span className={styles.checkboxMask}>
              <svg
                style={{ opacity: isAccepted ? 1 : 0 }}
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="12"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#000000"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-check"
              >
                <polyline points="20 6 9 17 4 12"></polyline>
              </svg>
            </span>
            <span className={styles.checkboxText}>
              {t('messages.shade_finder_terms_accept')}
            </span>
          </label>
          <Button
            onClick={handleAccepted}
            className={cn(
              styles.button,
              styles.submitButton,
              isAccepted && styles.isSelectable
            )}
          >
            {t('messages.shade_finder_terms_submit')}
          </Button>
        </div>
      </div>
    </motion.div>
  );
};
