import {
  ErrorNotice,
  HowTo,
  InterfaceToggle,
  Product,
  ProductGroupList,
  ProductRevealButton,
} from 'components';
import { FADE, VARIANT_LABELS } from 'constants/animations';
import { SHADE_FINDER } from 'constants/shadeFinder';
import { AnimatePresence, motion } from 'framer-motion';
import { EventCategories, useQuery, useTrackingEvent } from 'hooks';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { localeString } from 'sanity/locales';
import { CONCEALER_QUERY, LOOK_QUERY, SHADEFINDER_QUERY } from 'sanity/queries';
import { LooksQueryResult, LookType, TryOnQueryResult } from 'sanity/types';
import { GeolocationResponse } from 'types/shared';
import { getRegion } from 'utils/regions';
import sanityClient from '../../../sanity/client';
import * as Schema from '../../../sanity/schema';
import { useSettingsStore } from '../../../store/settingsStore';
import { LookItems } from '../LookItems/LookItems';
import { Looks } from '../Looks/Looks';
import styles from './ShadeFinderUserInterface.module.css';

export type SelectedProduct = {
  product: Schema.Sku;
  category?: string;
  productName: string;
  productPrice: string;
  hideProductName: boolean;
  type?: string;
};

export type ShadeRecommendation = {
  shadeType: string;
  skuId: string;
  skuNumber: number;
};

export const ShadeFinderUserInterface: React.FC = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const query = useQuery();
  const { setIsShoppable, setCountry, setRegion, isGucci } = useSettingsStore();
  const event = useTrackingEvent();

  const [isFinderComplete, setIsFinderComplete] = useState<boolean>(false);
  const [isShadeFinder, setIsShadeFinder] = useState<boolean>(true);
  const [type, setType] = useState<string>(SHADE_FINDER.FOUNDATION);
  const [currentProduct, setCurrentProduct] = useState<SelectedProduct>();
  const [selectedProducts, setSelectedProducts] = useState<
    Array<SelectedProduct>
  >([]);
  const [isProductSelected, setIsProductSelected] = useState<boolean>(false);
  const [productCategory, setProductCategory] = useState<string | undefined>(
    ''
  );
  const [detailIsSelected, setDetailIsSelected] = useState<boolean>(false);
  const [cmsData, setCMSData] = useState<TryOnQueryResult>();
  const [data, setData] = useState<TryOnQueryResult>();
  const [filtered, setFiltered] = useState<TryOnQueryResult>();
  const [isHowToOpen, setIsHowToOpen] = useState<boolean>(false);
  const [isProductUnavailable, setIsProductUnavailable] =
    useState<boolean>(false);
  const [shadeFinderRecommendations, setShadeFinderRecommendations] = useState<
    Array<ShadeRecommendation>
  >([]);
  const [isError, setIsError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isPhotoModalOpen, setIsPhotoModalOpen] = useState<boolean>(false);
  const [selectedSwatches, setSelectedSwatches] = useState<{
    foundation: SelectedProduct | undefined;
    concealer: SelectedProduct | undefined;
  }>();

  useEffect(() => {
    if (data) setProductCategory(data[0]._id);
  }, [data]);

  useEffect(() => {
    const appParam = query.get('app');
    const countryParam = query.get('country'); // gucci.com will always send through language & market params

    // If appParam or countryParam is available set to shoppable
    if (appParam || countryParam) setIsShoppable(true);
    // Set isShoppable to false if country being passed in is either
    // int or th from gucci.com
    if (countryParam && (countryParam === 'int' || countryParam === 'th')) {
      setIsShoppable(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const compare = window.YMK.addEventListener('compare', () => {
      event(
        EventCategories.ShadeFinder,
        'Clicks on Before/After',
        'enabled',
        isGucci
      );
    });
    const disableCompare = window.YMK.addEventListener(
      'compareDisabled',
      () => {
        event(
          EventCategories.ShadeFinder,
          'Clicks on Before/After',
          'disabled',
          isGucci
        );
      }
    );

    const closed = window.YMK.addEventListener('closed', () => {
      event(EventCategories.ShadeFinder, 'Clicks on Restart', '/', isGucci);
    });

    return () => {
      window.YMK.removeEventListener(compare);
      window.YMK.removeEventListener(disableCompare);
      window.YMK.removeEventListener(closed);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const isComplete = window.YMK.addEventListener(
      'shadeFinderComplete',
      // eslint-disable-next-line  @typescript-eslint/no-explicit-any
      (response: any) => {
        setIsFinderComplete(true);
        event(EventCategories.ShadeFinder, 'Successful Scan', '/', isGucci);

        if (response.recommendations.length > 0) {
          const recommendation = response.recommendations.find(
            // eslint-disable-next-line  @typescript-eslint/no-explicit-any
            (rec: any) => rec.order_index === 0
          );

          const shadeTypes = ['cooler', 'warmer', 'lighter', 'darker'];
          const skus =
            cmsData &&
            ((cmsData[0].products[0] as unknown as Schema.Product)
              .skus as unknown as Schema.Sku[]);

          // Sort the shades by sku name which is the first 3 characters of the sku name
          // Example: skuName = "260N", skuName = 260
          const shades: Array<ShadeRecommendation> = shadeTypes
            .filter((x) => recommendation[x].skuId)
            .map((shadeType) => {
              const skuId = recommendation[shadeType].skuId;
              // find the sku in the cms data and get the sku name
              const skuNumber = Number(
                skus
                  ?.find((x) => x.sku_code === skuId)
                  ?.sku_name?.en?.slice(0, 3)
              );
              return {
                shadeType,
                skuId,
                skuNumber,
              };
            })
            .sort((a, b) => {
              return a.skuNumber - b.skuNumber;
            });

          // Insert the matched shade in the middle of the array
          shades.splice(1, 0, {
            shadeType: 'ideal',
            skuId: recommendation['matched'].skuId,
            skuNumber: Number(recommendation['matched'].skuId.slice(-3)),
          });

          setShadeFinderRecommendations(shades);
        } else {
          setIsError(true);
          setErrorMessage('No recommendations returned');
        }
      }
    );

    return () => {
      window.YMK.removeEventListener(isComplete);
    };
  }, [event, isGucci, cmsData]);

  useEffect(() => {
    if (type === SHADE_FINDER.FOUNDATION) {
      sanityClient
        .fetch<TryOnQueryResult>(SHADEFINDER_QUERY, { type: type })
        .then((data) => setCMSData(data))
        .catch(console.error);
    }
    if (type === SHADE_FINDER.CONCEALER) {
      const product =
        filtered && (filtered[0].products[0] as unknown as Schema.Product);
      let idealRef = '';
      // Get unique concealer sku refs
      const skuRefs = Array.from(
        new Set(
          product?.skus?.map((sku) => {
            const x = sku as unknown as Schema.Sku;

            if (x.shadeType === 'ideal') {
              idealRef = x.matched_concealer?._ref || '';
            }
            return x.matched_concealer?._ref;
          })
        )
      );

      sanityClient
        .fetch<TryOnQueryResult>(CONCEALER_QUERY, {
          type: type,
          sku_refs: skuRefs.map((x) => x),
        })
        .then((data) => {
          const product = data[0].products[0] as unknown as Schema.Product;
          const skus = product.skus as unknown as Schema.Sku[];

          // we need to get the sku refs from the foundation products
          // to order the skus correctly
          setCMSData(
            data.map((categories) => {
              return {
                ...categories,
                products: categories.products.map(
                  (products: Schema.Product) => {
                    return {
                      ...products,
                      skus: skuRefs?.map((skuRef) => {
                        const sku = skus.find((x) => x._id === skuRef);

                        return {
                          ...sku,
                          shadeType: sku?._id === idealRef ? 'ideal' : '',
                        };
                      }),
                    };
                  }
                ),
              };
            }) as unknown as TryOnQueryResult
          );
        })
        .catch(console.error);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  useEffect(() => {
    const savePhotoModalOpened = window.YMK.addEventListener(
      'savePhotoModalOpened',
      () => {
        event(EventCategories.ShadeFinder, 'Clicks on camera', '/', isGucci);
        setIsPhotoModalOpen(true);
      }
    );

    const savePhotoModalClosed = window.YMK.addEventListener(
      'savePhotoModalClosed',
      () => {
        setIsPhotoModalOpen(false);
      }
    );

    return () => {
      window.YMK.removeEventListener(savePhotoModalOpened);
      window.YMK.removeEventListener(savePhotoModalClosed);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getCountryRegion = (country: string) => {
    const region = getRegion(country || '');

    // if the country isn't in a region,
    // then set the market to the country code
    if (region !== false) {
      return region || '';
    } else {
      return country || '';
    }
  };

  useEffect(() => {
    const filterData = async () => {
      if (cmsData && shadeFinderRecommendations.length > 0) {
        const params = new URLSearchParams(location.search);
        let market = '';

        const marketParam = params.get('market');

        // First try and match market from search params
        // We don't match any market param that starts with APP_ as that is the beauty app
        // and should be controlled by geolocation for market
        // If it doesn't exist match market from geolocation sent
        // by cloudfront and then lookup region in the getRegion method
        if (marketParam && !marketParam.startsWith('APP_')) {
          market = params.get('market') || '';
        } else if (params.get('country')) {
          const tmpCountry = params.get('country') || '';
          let country = '';
          // fix countries being passed in on gucci.com
          switch (tmpCountry) {
            case 'uk':
              country = 'GB';
              break;
            case 'zh':
              country = 'CN';
              break;
            default:
              country = tmpCountry.toUpperCase();
          }
          setCountry(country);
          const region = getCountryRegion(country || '');
          market = region;
          setRegion(region);
        } else {
          const country = await fetch('geolocation.json')
            .then((response) => response.json())
            .then((response: GeolocationResponse) => {
              return Object.values(response)[0];
            });
          setCountry(country || '');
          const region = getCountryRegion(country || '');
          market = region;
          setRegion(region);
        }

        if (market) {
          // Return the three skus from the cms data based on the recommendations
          // Add two new properties
          // 1) Unavailable - using the market value determine whether it's available in market
          // 2) Order - set the order so we can sort correctly
          // 3) ShadeType - for use with any display values.
          const filtered = cmsData.map((categories) => {
            return {
              ...categories,
              products: categories.products.map((products: Schema.Product) => {
                return {
                  ...products,
                  skus: products.skus
                    ?.filter((sku) => {
                      const item = { ...(sku as unknown as Schema.Sku) };
                      if (
                        shadeFinderRecommendations.find(
                          (x) => x.skuId === item.sku_code
                        )
                      ) {
                        return item;
                      }
                      return null;
                    })
                    .map((sku) => {
                      const item = { ...(sku as unknown as Schema.Sku) };
                      return {
                        ...item,
                        unavailable: !item.markets?.some(
                          // eslint-disable-next-line  @typescript-eslint/no-explicit-any
                          (item: any) => item.market_code === market
                        ),
                        index:
                          shadeFinderRecommendations.findIndex(
                            // eslint-disable-next-line  @typescript-eslint/no-explicit-any
                            (order: any) => order.skuId === item.sku_code
                          ) || 0,
                        shadeType: shadeFinderRecommendations.find(
                          (x) => x.skuId === item.sku_code
                        )?.shadeType,
                      };
                    })
                    // interate over the array and set
                    // unavailable to false if the market
                    // is int (for gucci.com)
                    .map((sku) => {
                      const item = { ...(sku as unknown as Schema.Sku) };
                      return {
                        ...item,
                        unavailable:
                          params.get('country') === 'int'
                            ? false
                            : item.unavailable,
                      };
                    })
                    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
                    .sort((a: any, b: any) => a.index - b.index),
                };
              }),
            };
          });

          switch (type) {
            case SHADE_FINDER.FOUNDATION:
              setData(filtered as unknown as TryOnQueryResult);
              // store in state to use for filtering on concealer
              setFiltered(filtered as unknown as TryOnQueryResult);
              break;
            case SHADE_FINDER.CONCEALER:
              setData(cmsData as unknown as TryOnQueryResult);
              break;
          }
        } else {
          setErrorMessage('No market found');
          setIsError(true);
        }
      }
    };

    filterData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cmsData, shadeFinderRecommendations, location.search]);

  const onHowToSelect = () => {
    setIsHowToOpen(true);
  };

  // useEffect(() => {
  //   window.YMK.reset();
  // }, [selectedProducts]);

  const onAddProductSku = (product: SelectedProduct) => {
    setDetailIsSelected(true);
    setIsProductSelected(true);

    window.YMK.reset();

    const products = selectedProducts.filter(
      (product) => product.type !== type
    );

    let selected = [
      ...products,
      { ...product, category: productCategory, type: type },
    ];

    setSelectedProducts(selected);

    setCurrentProduct({ ...product, category: productCategory, type: type });

    // If a new foundation shade has been selected, remove the concealer selection
    if (type === SHADE_FINDER.FOUNDATION) {
      const current = selectedProducts.find((x) => x.type === type);

      if (current && current?.product._id !== product.product._id) {
        selected = [{ ...product, category: productCategory, type: type }];
        setSelectedProducts(selected);
      }
    }

    // apply the selected products
    selected.forEach((product) => {
      window.YMK.applyMakeupBySku(product.product.sku_code);
    });

    event(
      EventCategories.ShadeFinder,
      type === SHADE_FINDER.FOUNDATION
        ? 'Clicks on shades'
        : 'Clicks on concealer',
      product.product.sku_name?.en || '',
      isGucci
    );

    const item = product.product as unknown as Schema.Sku & {
      unavailable: boolean;
    };

    if (item.unavailable) {
      setIsProductUnavailable(true);
    }

    setSelectedSwatches({
      concealer: selected.find((x) => x.type === 'CONCEALER') || undefined,
      foundation: selected.find((x) => x.type === 'FOUNDATION') || undefined,
    });
  };

  useEffect(() => {
    if (isShadeFinder && isFinderComplete) {
      window.YMK.enableCompare();
    } else {
      window.YMK.disableCompare();
    }
  }, [isFinderComplete, isShadeFinder]);

  // Looks

  const [looksData, setLooksData] = useState<LooksQueryResult>();
  const [currentLook, setCurrentLook] = useState<string>();
  const [looksCategory, setLooksCategory] = useState<string | undefined>('');
  const [isLookProductsOpen, setIsLookProductsOpen] = useState(false);

  const applyLook = useCallback(
    (look: string) => {
      window.YMK.applyMakeupByLook(look);

      if (selectedProducts.length > 0) {
        setTimeout(() => {
          selectedProducts.forEach((product) => {
            window.YMK.applyMakeupBySku(product?.product.sku_code);
          });
        }, 500);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentProduct]
  );

  useEffect(() => {
    sanityClient
      .fetch<LooksQueryResult>(LOOK_QUERY, {
        selectedCategory: 'Shadefinder',
      })
      .then((data) => {
        setLooksData(data);
      })
      .catch(console.error);
  }, []);

  useEffect(() => {
    if (looksData) {
      setLooksCategory(looksData[0]._id);
    }
  }, [looksData]);

  useEffect(() => {
    if (looksData && !isShadeFinder) {
      const look = looksData[0].looks[0] as unknown as Schema.Look;
      event(
        EventCategories.ShadeFinder,
        'Clicks on Looks',
        look.look_name?.en || '',
        isGucci
      );
      setCurrentLook(look.look_id);
      if (look.look_id) {
        applyLook(look.look_id);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [looksCategory, looksData, isShadeFinder, applyLook]);

  useEffect(() => {
    setIsLookProductsOpen(false);
  }, [currentLook]);

  const handleSelectLook = (look: string) => {
    const selectedLook =
      looksData &&
      (looksData[0].looks as unknown as Array<Schema.Look>).find(
        (x) => x.look_id === look
      );

    event(
      EventCategories.ShadeFinder,
      'Clicks on Looks',
      selectedLook?.look_name?.en || '',
      isGucci
    );
    setCurrentLook(look);
    applyLook(look);
  };

  return (
    <AnimatePresence>
      {isFinderComplete && (
        <motion.div
          key="interface"
          {...VARIANT_LABELS}
          variants={FADE}
          className={styles.wrapper}
          style={{ zIndex: isPhotoModalOpen ? -1 : 1 }}
        >
          <AnimatePresence exitBeforeEnter>
            {type === SHADE_FINDER.FOUNDATION ||
            type === SHADE_FINDER.CONCEALER ? (
              <motion.div
                key="shade finder"
                variants={FADE}
                className={styles.interface}
              >
                <ErrorNotice
                  isOpen={isError}
                  setIsOpen={setIsError}
                  message={errorMessage}
                  buttonText="OK"
                />

                {isProductSelected && (
                  <>
                    <ProductRevealButton
                      onClick={() => setDetailIsSelected(!detailIsSelected)}
                      text={
                        detailIsSelected ? (
                          <>{t('messages.show_less')}</>
                        ) : (
                          <>{t('messages.show_more')}</>
                        )
                      }
                      detailIsSelected={detailIsSelected}
                    />
                    <Product
                      productName={currentProduct?.productName || ''}
                      productPrice={currentProduct?.productPrice || ''}
                      skuName={
                        localeString(currentProduct?.product.sku_name) || ''
                      }
                      skuCode={currentProduct?.product.sku_code || ''}
                      imageId={
                        currentProduct?.product.sku_image?.asset._ref || ''
                      }
                      isLookItem={false}
                      hideProductName={currentProduct?.hideProductName || false}
                      detailIsSelected={detailIsSelected}
                      isShadeFinder={isShadeFinder}
                      isUnavailable={currentProduct?.product.unavailable}
                      onHowToSelect={onHowToSelect}
                    />
                    <HowTo
                      isOpen={isHowToOpen}
                      setIsOpen={setIsHowToOpen}
                      sku={currentProduct?.product.sku_code || ''}
                      skinTone={currentProduct?.product.skin_tone || ''}
                      isUnavailable={currentProduct?.product.unavailable}
                      productPrice={currentProduct?.productPrice || ''}
                      category={productCategory || ''}
                      type={type}
                    />
                    <ErrorNotice
                      isOpen={isProductUnavailable}
                      setIsOpen={setIsProductUnavailable}
                      message={t('messages.shade_finder_unavailable_message')}
                      buttonText={t('messages.shade_finder_unavailable_button')}
                    />
                  </>
                )}
                {!isError && (
                  <ProductGroupList
                    productCategories={data}
                    productCategory={productCategory}
                    productIsSelected={isProductSelected}
                    onAddProductSku={onAddProductSku}
                    selectedProducts={selectedProducts}
                    detailIsSelected={detailIsSelected}
                    isShadeFinder={isShadeFinder}
                    shadeFinderType={type}
                  />
                )}
              </motion.div>
            ) : (
              <motion.div
                key="looks"
                variants={FADE}
                className={styles.interface}
              >
                {currentLook && (
                  <>
                    <ProductRevealButton
                      onClick={() => {
                        event(
                          EventCategories.ShadeFinder,
                          !isLookProductsOpen
                            ? 'Clicks on Show more - Gucci Looks'
                            : 'Clicks on Show less - Gucci Looks',
                          '/',
                          isGucci
                        );
                        setIsLookProductsOpen(!isLookProductsOpen);
                      }}
                      text={
                        isLookProductsOpen ? (
                          <>{t('messages.show_less')}</>
                        ) : (
                          <>{t('messages.show_more')}</>
                        )
                      }
                      detailIsSelected={isLookProductsOpen}
                    />
                    <LookItems
                      look={
                        (looksData
                          ?.find((category) => category._id === looksCategory)
                          ?.looks.find(
                            (look: Schema.Look) => look.look_id === currentLook
                          ) as LookType) || undefined
                      }
                      isOpen={isLookProductsOpen}
                      shadeProduct={currentProduct}
                    />
                  </>
                )}
                <Looks
                  looks={
                    looksData?.find((x) => x._id === looksCategory)?.looks || []
                  }
                  onLookSelected={handleSelectLook}
                  selectedLook={currentLook || ''}
                  categoryName={localeString(
                    looksData?.find(
                      (category) => category._id === looksCategory
                    )?.category_name
                  )}
                  isOpen={isLookProductsOpen}
                  hasSingleCategory={true}
                />
              </motion.div>
            )}
          </AnimatePresence>
          {!isError && (
            <InterfaceToggle
              type={type}
              setType={setType}
              selectedSwatches={selectedSwatches}
              isShadeFinder={isShadeFinder}
              setIsShadeFinder={setIsShadeFinder}
            />
          )}
        </motion.div>
      )}
    </AnimatePresence>
  );
};
