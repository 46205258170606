export const LANGUAGE_CODES = {
  ENGLISH: 'en',
  FRENCH: 'fr',
  GERMAN: 'de',
  SPANISH: 'es',
  ITALIAN: 'it',
  KOREAN: 'ko',
  JAPANESE: 'ja',
  CHINESE: 'zh',
  CHINESE_HK: 'zh-hk',
  ARABIC: 'ar',
};

// export const LANGUAGE = {

export const LANGUAGES = [
  { lang: 'en', url: 'uk/en', name: 'ENGLISH', vtoLang: 'enu' },
  { lang: 'fr', url: 'fr/fr', name: 'FRANÇAIS', vtoLang: 'fra' },
  { lang: 'de', url: 'de/de', name: 'DEUTSCH', vtoLang: 'deu' },
  { lang: 'es', url: 'es/es', name: 'ESPAÑOL', vtoLang: 'esp' },
  { lang: 'it', url: 'it/it', name: 'ITALIANO', vtoLang: 'ita' },
  { lang: 'ko', url: 'ko/ko', name: '한국어', vtoLang: 'kor' },
  { lang: 'ja', url: 'ja/ja', name: '日本語', vtoLang: 'jpn' },
  { lang: 'zh', url: 'zh/zh', name: '简体中文', vtoLang: 'chs' },
  { lang: 'zh-cn', url: 'hk/zh-Hans-HK', name: '繁體中文', vtoLang: 'cht' },
  { lang: 'ar', url: 'ar/ar', name: 'العربية', vtoLang: 'enu' },
];
