import { Button } from 'components';
import {
  DEFAULT_TRANSTION,
  EASINGS,
  VARIANT_LABELS,
} from 'constants/animations';
import { motion, Variants } from 'framer-motion';
import { EventCategories, useTrackingEvent } from 'hooks';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ExperienceType } from 'types/shared';
import styles from './TryOnMenu.module.css';

interface TryOnMenuProps {
  onLinkSelected: (type: ExperienceType) => void;
}

interface MenuLinkProps {
  text: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  activeVariants?: Variants;
  isVisible?: boolean;
  type: ExperienceType;
}

const wrapperVariants: Variants = {
  initial: { opacity: 0 },
  animate: {
    opacity: 1,
    transition: {
      duration: 0,
      delayChildren: 0.4,
      staggerChildren: 0.1,
      when: 'beforeChildren',
      staggerDirection: -1,
    },
  },
};

const linkVariants: Variants = {
  initial: { opacity: 0, y: '-50px' },
  animate: {
    opacity: 1,
    y: '0',
    transition: {
      duration: 1,
      ease: EASINGS.easeOut,
    },
  },
};

const setActiveLinkVariants = (selectedLink: boolean | undefined) => {
  const variants: Variants = {
    initial: { opacity: 0 },
    animate: {
      opacity: selectedLink ? 1 : 0,
      transition: { ...DEFAULT_TRANSTION },
    },
    exit: { opacity: 0, transition: { ...DEFAULT_TRANSTION } },
  };
  return variants;
};

export const TryOnMenu: React.FC<TryOnMenuProps> = ({ onLinkSelected }) => {
  const { t, i18n } = useTranslation();
  const [links, setLinks] = useState<Array<MenuLinkProps>>([]);
  const event = useTrackingEvent();

  useEffect(() => {
    setLinks([
      {
        text: t('messages.try_on_menu_makeup'),
        type: 'MAKEUP' as ExperienceType,
        isVisible: true,
      },
      {
        text: t('messages.try_on_menu_nails'),
        type: 'NAILS' as ExperienceType,
        isVisible: true,
      },
      {
        text: t('messages.try_on_menu_looks'),
        type: 'LOOKS' as ExperienceType,
        isVisible: true,
      },
    ]);
  }, [t, i18n.language]);

  const onSelected = (selected: MenuLinkProps) => {
    const linksWithActiveState = links.map((link: MenuLinkProps) => {
      return {
        ...link,
        isVisible: link === selected && true,
      };
    });
    setLinks(linksWithActiveState);
    onLinkSelected(selected.type);
    event(EventCategories.Beauty, `Click on ${selected.type}`, '/', false);
  };

  useEffect(() => {
    const oneTrustElement = document.getElementById('onetrust-consent-sdk');
    if (oneTrustElement) {
      oneTrustElement.style.display = 'block';
    }
  }, []);

  return (
    <motion.ul
      {...VARIANT_LABELS}
      variants={wrapperVariants}
      className={styles.wrapper}
    >
      {links &&
        links.map((link: MenuLinkProps) => {
          return (
            <li className={styles.menuListItem} key={link.text}>
              <Button
                onClick={() => onSelected(link)}
                isAnimated={true}
                variants={linkVariants}
                className={styles.menuLink}
              >
                <motion.span
                  {...VARIANT_LABELS}
                  variants={setActiveLinkVariants(link.isVisible)}
                >
                  {link.text}
                </motion.span>
              </Button>
            </li>
          );
        })}
    </motion.ul>
  );
};
