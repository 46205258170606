import {
  Experience,
  Intro,
  Loading,
  Menu,
  Root,
  Terms,
  VideoSequence,
} from 'components';
import { SCRIPTS } from 'constants/scripts';
import {
  initialPageState,
  PAGE_ACTIONS,
  PageStateContext,
  pageStateReducer,
} from 'context/PageState';
import {
  initialVideoState,
  VideoStateContext,
  videoStateReducer,
} from 'context/VideoState';
import { AnimatePresence } from 'framer-motion';
import { useScript } from 'hooks';
import { useEffect, useReducer, useState } from 'react';
import { useSettingsStore } from 'store/settingsStore';
import { ExperienceType } from 'types/shared';
import { waitForElement } from 'utils/waitForElement';

interface ExperienceTemplateProps {
  isShadeFinder: boolean;
}

export const ExperienceTemplate: React.FC<ExperienceTemplateProps> = ({
  isShadeFinder,
}) => {
  const [pageState, pageDispatch] = useReducer(
    pageStateReducer,
    initialPageState
  );
  const [videoState, videoDispatch] = useReducer(
    videoStateReducer,
    initialVideoState
  );

  const [moduleType, setModuleType] = useState<ExperienceType>(
    isShadeFinder ? 'SHADE_FINDER' : 'MAKEUP'
  );

  const script = useScript(SCRIPTS[moduleType], moduleType);
  const { setExperienceType } = useSettingsStore();

  useEffect(() => {
    if (isShadeFinder) {
      waitForElement('#ot-sdk-btn-floating').then((cookie) => {
        const element = cookie as unknown as HTMLElement;
        element.classList.add('cookie-hide');
      });
    }
  }, [isShadeFinder]);

  return (
    <PageStateContext.Provider value={{ pageState, pageDispatch }}>
      <VideoStateContext.Provider value={{ videoState, videoDispatch }}>
        <Root>
          <AnimatePresence exitBeforeEnter>
            {(() => {
              switch (true) {
                case pageState.loadingIsActive:
                  return (
                    <Loading key={PAGE_ACTIONS.LOADING} type={moduleType} />
                  );
                case pageState.introIsActive:
                  return <Intro key={PAGE_ACTIONS.INTRO} />;
                case pageState.menuIsActive:
                  return (
                    <Menu
                      key={PAGE_ACTIONS.MENU}
                      isShadeFinder={isShadeFinder}
                      onLinkSelect={(type: ExperienceType) => {
                        setExperienceType(type);
                        setModuleType(type);
                      }}
                    />
                  );
                case pageState.termsIsActive:
                  return (
                    <Terms
                      isShadeFinder={isShadeFinder}
                      key={PAGE_ACTIONS.TERMS}
                    />
                  );
                case pageState.experienceIsActive:
                  return (
                    <Experience
                      key={PAGE_ACTIONS.EXPERIENCE}
                      script={script}
                      type={moduleType}
                    />
                  );
                default:
                  break;
              }
            })()}
          </AnimatePresence>
          <VideoSequence isShadeFinder={isShadeFinder} />
        </Root>
      </VideoStateContext.Provider>
    </PageStateContext.Provider>
  );
};

export default ExperienceTemplate;
