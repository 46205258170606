import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { DeviceNotice } from 'components';
import { LANGUAGES } from 'constants/locales';
import { ROUTES } from 'constants/routes';
import { useQuery, useScript } from 'hooks';
import { useDeviceId } from 'hooks/useDeviceId';
import i18n from 'i18n';
import { ShadeFinderPage, TryOnPage } from 'pages';
import { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import { useSettingsStore } from 'store/settingsStore';
import { GeolocationResponse } from 'types/shared';

const queryClient = new QueryClient();

const App = () => {
  const regionsBlocked = ['Illinois'];
  const [showBlockMessage, setShowBlockMessage] = useState<boolean>(false);
  const [showDeviceNotice, setShowDeviceNotice] = useState<boolean>(true);
  const query = useQuery();
  const { setIsGucci, currentLanguage } = useSettingsStore();
  const healthCheck = useScript(
    `https://88oik3kum4.execute-api.eu-west-1.amazonaws.com/?monitoring=${query.get(
      'monitoring'
    )}`,
    'playhealthcheck',
    'healthcheck'
  );
  const deviceId = useDeviceId();

  useEffect(() => {
    if (healthCheck === 'ready' && query.get('monitoring')) {
      // @ts-expect-error - "this is a global variable"
      Play.config = {
        clientID: 'COTY_BR04', // this is unique to each client
        projectID: 'GUCCI_MON01', // this is unique to the clients project
        deviceID: deviceId, // should be unique for each device
      };
    }
  }, [healthCheck, deviceId, query]);

  useEffect(() => {
    const checkGeolocation = async () => {
      const regionName = await fetch('geolocation.json')
        .then((response) => response.json())
        .then((response: GeolocationResponse) => {
          return response['cloudfront-viewer-country-region-name'];
        });

      if (regionName && regionsBlocked.includes(regionName)) {
        setShowBlockMessage(true);
      }
    };
    if (query.get('country')) {
      setIsGucci(true);
      return;
    }
    checkGeolocation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const hostname = window.location.hostname;
    if (hostname === 'ggshadefinder.gucci.com') setShowDeviceNotice(false);
  }, []);

  useEffect(() => {
    if (currentLanguage) {
      handleLanguageSelect(currentLanguage);
    }
  }, [currentLanguage]);

  const handleLanguageSelect = (languageCode: string) => {
    window.document.documentElement.lang = languageCode;
    i18n.changeLanguage(languageCode);
  };

  useEffect(() => {
    let detectedLanguage = navigator.language.toLowerCase() || currentLanguage;

    if (
      detectedLanguage !== 'zh-cn' &&
      detectedLanguage !== 'zh-tw' &&
      detectedLanguage !== 'zh-hk'
    ) {
      const twoDigitCode = detectedLanguage.split('-')[0];
      const supportedLanguages = LANGUAGES.map((x) => x.lang);
      detectedLanguage = supportedLanguages.includes(twoDigitCode)
        ? twoDigitCode
        : detectedLanguage;
    }
    // if the language detected is zh-tw (Taiwan)
    // map to zh-hk for traditional chinese
    if (detectedLanguage === 'zh-tw') {
      detectedLanguage = 'zh-hk';
    }
    // if the language detected is zh-cn (China)
    // map to zh for simplified chinese
    if (detectedLanguage === 'zh-cn') {
      detectedLanguage = 'zh';
    }
    // Map language codes from query param to language codes used in the app
    const langFromParam = query.get('language');
    if (langFromParam) {
      switch (langFromParam) {
        case 'en_gb':
        case 'en_au':
          detectedLanguage = 'en';
          break;
        case 'zh_hk':
          detectedLanguage = 'zh-hk';
          break;
        case 'zh':
          detectedLanguage = 'zh';
          break;
        default:
          detectedLanguage = langFromParam;
      }
    }
    handleLanguageSelect(detectedLanguage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      {process.env.NODE_ENV === 'production' && showBlockMessage ? (
        <div
          style={{
            position: 'fixed',
            top: 0,
            right: 0,
            left: 0,
            bottom: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          This site is unavailable in your region
        </div>
      ) : (
        <Routes>
          <Route path={ROUTES.TRY_ON} element={<TryOnPage />} />
          <Route path={ROUTES.SHADE_FINDER} element={<ShadeFinderPage />} />
          <Route path="*" element={<TryOnPage />} />
        </Routes>
      )}
      {process.env.NODE_ENV === 'production' && showDeviceNotice && (
        <DeviceNotice />
      )}
    </QueryClientProvider>
  );
};

export default App;
