import cn from 'classnames';
import { Button, ProductImage, ProductName } from 'components';
import 'components/atoms/LoaderSmall/LoaderSmall';
import { DEFAULT_TRANSTION, VARIANT_LABELS } from 'constants/animations';
import { SHADE_FINDER } from 'constants/shadeFinder';
import { AnimatePresence, motion, Variants } from 'framer-motion';
import { EventCategories, useTrackingEvent } from 'hooks';
import { useTranslation } from 'react-i18next';
import { useSettingsStore } from 'store/settingsStore';
import { addToCart, getShopUrl } from 'utils/shopping';
import styles from './Product.module.css';

interface ProductProps {
  skuName: string;
  skuCode?: string;
  productName: string;
  productPrice: string;
  imageId: string;
  isLookItem: boolean;
  hideProductName: boolean;
  detailIsSelected?: boolean;
  isShadeFinder?: boolean;
  isUnavailable?: boolean;
  onHowToSelect?: () => void;
  shadeFinderType?: string;
}

export const productVariants: Variants = {
  initial: { opacity: 0 },
  animate: {
    opacity: 1,
    transition: { ...DEFAULT_TRANSTION },
  },
};

export const Product: React.FC<ProductProps> = ({
  skuName,
  skuCode,
  productName,
  productPrice,
  imageId,
  isLookItem,
  hideProductName,
  detailIsSelected,
  isShadeFinder,
  isUnavailable,
  onHowToSelect,
  shadeFinderType,
}) => {
  const { t } = useTranslation();
  const { isShoppable, isGucci, currentCountry } = useSettingsStore();
  const event = useTrackingEvent();

  const handleHowToSelect = () => {
    if (onHowToSelect) {
      onHowToSelect();
    }
  };

  const getCartButton = () => {
    return (
      <>
        <svg
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <line
            x1="11.95"
            y1="2.96631"
            x2="16.31"
            y2="2.96631"
            stroke="black"
            strokeWidth="1.5"
            strokeLinecap="round"
          />
          <line
            x1="14.0591"
            y1="0.75"
            x2="14.0591"
            y2="5.11396"
            stroke="black"
            strokeWidth="1.5"
            strokeLinecap="round"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.1333 7.91996H9.89332V6.05329C9.89332 5.02662 8.68003 4.18662 7.09336 4.18662C5.5067 4.18662 4.29336 4.93329 4.29336 6.05329V7.91996H1.77336C0.840029 7.91996 0.280029 8.38662 0.280029 9.03996L0.746696 16.32H13.2533L13.8133 9.13329C13.8133 8.38662 13.0666 7.91996 12.1333 7.91996ZM5.69336 6.05329C5.7867 5.95996 6.25336 5.58662 7.09336 5.58662C7.93336 5.58662 8.40003 5.86662 8.49336 6.05329V7.91996H5.69336V6.05329ZM2.05336 14.92H11.9466L12.32 9.41329H12.1333H1.77336H1.68003L2.05336 14.92Z"
            fill="black"
          />
        </svg>
      </>
    );
  };

  return (
    <div
      className={cn(
        styles.wrapper,
        isLookItem && styles.isLookItem,
        detailIsSelected && styles.detailIsSelected
      )}
    >
      <AnimatePresence exitBeforeEnter>
        <motion.div
          key="product"
          {...VARIANT_LABELS}
          variants={productVariants}
          className={cn(styles.inner, isLookItem && styles.isLookItem)}
        >
          <ProductImage imageId={imageId} />
          <ProductName
            skuName={skuName}
            productName={productName}
            productPrice={productPrice}
            hideProductName={hideProductName}
            isShoppable={isShoppable}
            isUnavailable={isUnavailable}
          />
          {isShadeFinder && (
            <div className={styles.shadeFinderButtons}>
              <Button
                onClick={handleHowToSelect}
                className={cn(styles.shadeFinderButton, styles.howToButton)}
              >
                {t('messages.shade_finder_how_to_button')}
              </Button>
              {isShoppable && !isUnavailable && productPrice && (
                <>
                  {isGucci ? (
                    <button
                      className={cn(
                        styles.shadeFinderButton,
                        styles.cartButton
                      )}
                      onClick={() => {
                        event(
                          EventCategories.ShadeFinder,
                          'Clicks on Shop - Shade Match',
                          skuName,
                          isGucci
                        );
                        addToCart(
                          skuCode || '',
                          shadeFinderType === SHADE_FINDER.FOUNDATION
                            ? true
                            : false,
                          currentCountry
                        );
                      }}
                    >
                      {getCartButton()}
                    </button>
                  ) : (
                    <a
                      href={getShopUrl(
                        skuCode || '',
                        shadeFinderType === SHADE_FINDER.FOUNDATION
                          ? true
                          : false,
                        currentCountry
                      )}
                      target="_blank"
                      rel="noreferrer"
                      className={cn(
                        styles.shadeFinderButton,
                        styles.cartButton
                      )}
                    >
                      {getCartButton()}
                    </a>
                  )}
                </>
              )}
            </div>
          )}
        </motion.div>
      </AnimatePresence>
    </div>
  );
};
