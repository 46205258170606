import { Button } from 'components';
import { DEFAULT_TRANSTION, FADE, VARIANT_LABELS } from 'constants/animations';
import { AnimatePresence, motion } from 'framer-motion';
import { EventCategories, useTrackingEvent } from 'hooks';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { urlFor } from 'sanity/images';
import { localeString } from 'sanity/locales';
import { HOWTO_QUERY } from 'sanity/queries';
import { HowToQueryResult } from 'sanity/types';
import { useSettingsStore } from 'store/settingsStore';
import { addToCart, getShopUrl } from 'utils/shopping';
import sanityClient from '../../../sanity/client';
import styles from './HowTo.module.css';

interface HowToItem {
  image: string;
  video?: string;
  local: boolean;
}

interface HowToProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  sku: string;
  category: string;
  skinTone: string;
  isUnavailable?: boolean;
  productPrice: string;
  type: string;
}

export const HowTo: React.FC<HowToProps> = ({
  isOpen,
  setIsOpen,
  isUnavailable,
  sku,
  category,
  skinTone,
  productPrice,
  type,
}) => {
  const { t } = useTranslation();
  const { isShoppable, isGucci, currentCountry } = useSettingsStore();
  const event = useTrackingEvent();

  const [items, setItems] = useState<Array<HowToItem>>();
  const [isSelected, setIsSelected] = useState<HowToItem>();
  const [isVideoPlaying, setIsVideoPlaying] = useState<boolean>(false);
  const [isWebMSupported, setIsWebMSupported] = useState<boolean>(false);
  const [cmsData, setCMSData] = useState<HowToQueryResult>();

  const videoRef = useRef<HTMLVideoElement | null>(null);

  useEffect(() => {
    sanityClient
      .fetch<HowToQueryResult>(HOWTO_QUERY, {
        sku: sku,
        category: category,
        skinTone: skinTone,
      })
      .then((data) => setCMSData(data))
      .catch(console.error);
  }, [sku, skinTone, category]);

  useEffect(() => {
    const items: Array<HowToItem> = [];

    // add video
    items.push({
      image: `${
        process.env.PUBLIC_URL
      }/images/howto/${type.toLowerCase()}/${skinTone.toLowerCase()}.jpg`,
      video: skinTone.toLowerCase(),
      local: true,
    });

    // add sku specific images
    cmsData?.sku.howto_images?.forEach((img) => {
      items.push({ image: img.asset._ref, local: false });
    });

    // get the country and then add all SkinTone specific
    // images from the CMS.

    if (cmsData?.grid_shade_family) {
      items.push({
        image: cmsData?.grid_shade_family?.asset._ref || '',
        local: false,
      });
    }
    if (currentCountry === 'CN') {
      if (cmsData?.swatch_cn) {
        items.push({
          image: cmsData?.swatch_cn?.asset._ref || '',
          local: false,
        });
      }
    } else {
      if (cmsData?.swatch) {
        items.push({
          image: cmsData?.swatch?.asset._ref || '',
          local: false,
        });
      }
    }

    if (cmsData?.ingredients) {
      items.push({
        image: cmsData?.ingredients?.asset._ref || '',
        local: false,
      });
    }

    if (cmsData?.infographic) {
      items.push({
        image: cmsData?.infographic?.asset._ref || '',
        local: false,
      });
    }

    if (currentCountry === 'CN') {
      if (cmsData?.comparative_chart_cn) {
        items.push({
          image: cmsData?.comparative_chart_cn?.asset._ref || '',
          local: false,
        });
      }
    } else {
      if (cmsData?.comparative_chart) {
        items.push({
          image: cmsData?.comparative_chart?.asset._ref || '',
          local: false,
        });
      }
    }

    if (cmsData?.key_visual) {
      items.push({
        image: cmsData?.key_visual?.asset._ref || '',
        local: false,
      });
    }

    setItems(items);
    setIsSelected(items[0]);
  }, [cmsData, skinTone, currentCountry, type]);

  const handleExit = () => {
    setIsOpen(false);
  };

  const handleSelected = (item: HowToItem) => {
    setIsSelected(item);
    if (isVideoPlaying) {
      setIsVideoPlaying(false);
    } else {
      event(
        EventCategories.ShadeFinder,
        'Clicks on "How To Use"',
        'Clicks on images',
        isGucci
      );
    }
  };

  const handleVideoPlay = () => {
    if (videoRef.current) {
      event(
        EventCategories.ShadeFinder,
        'Clicks on "How To Use"',
        'Clicks on play the video',
        isGucci
      );
      if (!isVideoPlaying) {
        setIsVideoPlaying(true);
        videoRef.current.play();
      } else {
        setIsVideoPlaying(false);
        videoRef.current.pause();
      }
    }
  };

  const handleVideoEnd = () => {
    if (videoRef.current) {
      setIsVideoPlaying(false);
      videoRef.current.pause();
      videoRef.current.currentTime = 0;
    }
  };

  useEffect(() => {
    if (videoRef.current) {
      if (videoRef.current.canPlayType('video/webm; codecs="vp8, vorbis"')) {
        setIsWebMSupported(true);
      } else {
        setIsWebMSupported(false);
      }
    }
  }, []);

  const imageVariants = {
    initial: {
      opacity: 1,
      transition: {
        ...DEFAULT_TRANSTION,
      },
    },
    animate: {
      opacity: 0,
      transition: {
        ...DEFAULT_TRANSTION,
      },
    },
  };

  const getCartButton = () => {
    return (
      <>
        <span className={styles.cartButtonLabel}>
          {t('messages.shade_finder_how_to_shop_now')}
        </span>
        <span>{productPrice}</span>
        <svg
          className={styles.cartButtonIcon}
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <line
            x1="11.95"
            y1="2.96631"
            x2="16.31"
            y2="2.96631"
            stroke="black"
            strokeWidth="1.5"
            strokeLinecap="round"
          />
          <line
            x1="14.0591"
            y1="0.75"
            x2="14.0591"
            y2="5.11396"
            stroke="black"
            strokeWidth="1.5"
            strokeLinecap="round"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.1333 7.91996H9.89332V6.05329C9.89332 5.02662 8.68003 4.18662 7.09336 4.18662C5.5067 4.18662 4.29336 4.93329 4.29336 6.05329V7.91996H1.77336C0.840029 7.91996 0.280029 8.38662 0.280029 9.03996L0.746696 16.32H13.2533L13.8133 9.13329C13.8133 8.38662 13.0666 7.91996 12.1333 7.91996ZM5.69336 6.05329C5.7867 5.95996 6.25336 5.58662 7.09336 5.58662C7.93336 5.58662 8.40003 5.86662 8.49336 6.05329V7.91996H5.69336V6.05329ZM2.05336 14.92H11.9466L12.32 9.41329H12.1333H1.77336H1.68003L2.05336 14.92Z"
            fill="black"
          />
        </svg>
      </>
    );
  };

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          {...VARIANT_LABELS}
          variants={FADE}
          className={styles.wrapper}
        >
          <div className={styles.modal}>
            <Button className={styles.exitButton} onClick={handleExit}>
              <svg
                width="13"
                height="13"
                viewBox="0 0 13 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2 2L11 11"
                  stroke="white"
                  strokeWidth="2.5"
                  strokeLinecap="round"
                />
                <path
                  d="M11 2L2 11"
                  stroke="white"
                  strokeWidth="2.5"
                  strokeLinecap="round"
                />
              </svg>
            </Button>
            <div className={styles.selectedItem}>
              <div className={styles.media}>
                <motion.img
                  initial="initial"
                  animate={isVideoPlaying ? 'animate' : 'initial'}
                  variants={imageVariants}
                  src={
                    isSelected &&
                    (isSelected.local
                      ? isSelected.image
                      : urlFor(isSelected.image || '').url())
                  }
                  className={styles.image}
                />
                {isSelected && isSelected.video && (
                  <>
                    <Button
                      key={'video play button'}
                      className={styles.videoButton}
                      onClick={handleVideoPlay}
                      style={{ opacity: isVideoPlaying ? 0 : 1 }}
                    >
                      <svg
                        width="33"
                        height="39"
                        viewBox="0 0 33 39"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M33 19.5L0.749998 38.1195L0.75 0.880452L33 19.5Z"
                          fill="white"
                          fillOpacity="0.8"
                        />
                      </svg>
                    </Button>
                    <video
                      ref={videoRef}
                      src={`${
                        process.env.PUBLIC_URL
                      }/videos/howto/${type.toLowerCase()}/${
                        isSelected.video
                      }.${isWebMSupported ? 'webm' : 'mov'}`}
                      playsInline
                      controls={false}
                      disablePictureInPicture
                      preload={'auto'}
                      onEnded={handleVideoEnd}
                      className={styles.video}
                    />
                  </>
                )}
              </div>
              <div className={styles.details}>
                <div className={styles.header}>
                  <div className={styles.name}>
                    {`${localeString(cmsData?.sku?.sku_name)} ${localeString(
                      cmsData?.sku.product.product_name
                    )}`}
                  </div>
                  {isShoppable && !isUnavailable && productPrice && (
                    <>
                      {isGucci ? (
                        <button
                          className={styles.cartButton}
                          onClick={() => {
                            event(
                              EventCategories.ShadeFinder,
                              'Clicks on Shop - Shade Match',
                              cmsData?.sku.sku_name?.en || '',
                              isGucci
                            );
                            addToCart(sku, true, currentCountry);
                          }}
                        >
                          {getCartButton()}
                        </button>
                      ) : (
                        <a
                          href={getShopUrl(sku, true, currentCountry)}
                          target="_blank"
                          rel="noreferrer"
                          className={styles.cartButton}
                        >
                          {getCartButton()}
                        </a>
                      )}
                    </>
                  )}
                </div>
                <div className={styles.title}>
                  {localeString(cmsData?.title)}
                </div>
                <p
                  className={styles.description}
                  dangerouslySetInnerHTML={{
                    __html: localeString(cmsData?.copy) || '',
                  }}
                />
              </div>
            </div>
            <div className={styles.menuWrapper}>
              <div className={styles.menuHeading}>
                {t('messages.shade_finder_how_to_more_topics')}
              </div>
              <div className={styles.menu}>
                <ul className={styles.menuItems}>
                  {items?.map((item, index) => (
                    <li key={index} className={styles.menuItem}>
                      <img
                        src={item.local ? item.image : urlFor(item.image).url()}
                        onClick={() => handleSelected(item)}
                        className={styles.menuItemImage}
                        alt="product"
                        role="presentation"
                      />
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};
