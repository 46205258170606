import {
  LoaderSmall,
  Product,
  ProductCategoryList,
  ProductGroupList,
  ProductRevealButton,
} from 'components';
import { FADE, VARIANT_LABELS } from 'constants/animations';
import { AnimatePresence, motion } from 'framer-motion';
import { EventCategories, useTrackingEvent } from 'hooks';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { localeString } from 'sanity/locales';
import { MAKEUP_QUERY } from 'sanity/queries';
import sanityClient from '../../../sanity/client';
import type * as Schema from '../../../sanity/schema';
import type { TryOnQueryResult } from '../../../sanity/types';
import styles from './TryOnUserInterface.module.css';

export type SelectedProduct = {
  product: Schema.Sku;
  category?: string;
  productName: string;
  productPrice: string;
  hideProductName: boolean;
  type?: string;
};

interface TryOnUserInterfaceProps {
  type: 'MAKEUP' | 'NAILS';
}

export const TryOnUserInterface: React.FC<TryOnUserInterfaceProps> = ({
  type,
}) => {
  const [data, setData] = useState<TryOnQueryResult>();
  const [currentProduct, setCurrentProduct] = useState<SelectedProduct>();
  const [selectedProducts, setSelectedProducts] = useState<
    Array<SelectedProduct>
  >([]);
  const [isProductSelected, setIsProductSelected] = useState<boolean>(false);
  const [productCategory, setProductCategory] = useState<string | undefined>(
    ''
  );
  const [detailIsSelected, setDetailIsSelected] = useState<boolean>(false);

  const { t } = useTranslation();
  const event = useTrackingEvent();

  useEffect(() => {
    sanityClient
      .fetch<TryOnQueryResult>(MAKEUP_QUERY, {
        selectedCategory: type === 'MAKEUP' ? 'Makeup' : 'Nails',
      })
      .then((data) => setData(data))
      .catch(console.error);
  }, [type]);

  useEffect(() => {
    if (data) {
      const firstItem = data[0];
      if (firstItem._id !== productCategory) {
        event(
          EventCategories.Beauty,
          `Clicks on ${firstItem.category_section} category`,
          firstItem.category_name?.en || '',
          false
        );
        setProductCategory(firstItem._id);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    const selectedProduct = selectedProducts.find(
      (item) => item.category === productCategory
    ) as SelectedProduct;

    if (selectedProduct) {
      setIsProductSelected(true);
      setCurrentProduct(selectedProduct);
    }
  }, [productCategory, selectedProducts]);

  const onAddProductSku = (product: SelectedProduct) => {
    if (product.product.sku_code === currentProduct?.product.sku_code) {
      onRemoveProductSku();
      setCurrentProduct(undefined);
    } else {
      setDetailIsSelected(true);
      setIsProductSelected(true);

      const products = selectedProducts.filter(
        (product) => product.category !== productCategory
      );

      setSelectedProducts([
        ...products,
        { ...product, category: productCategory },
      ]);

      event(
        EventCategories.Beauty,
        `Clicks on ${type} product`,
        product.productName,
        false
      );

      setCurrentProduct({ ...product, category: productCategory });
      window.YMK.applyMakeupBySku(product.product.sku_code);
    }
  };

  const onRemoveProductSku = () => {
    setIsProductSelected(false);
    const products = selectedProducts.filter(
      (product) => product !== currentProduct
    );

    setSelectedProducts(products);
    window.YMK.reset();

    for (const product of products) {
      window.YMK.applyMakeupBySku(product.product.sku_code);
    }
  };

  useEffect(() => {
    const oneTrustElement = document.getElementById('onetrust-consent-sdk');
    if (oneTrustElement) {
      oneTrustElement.style.display = 'none';
    }
  }, []);

  useEffect(() => {
    const compare = window.YMK.addEventListener('compare', () => {
      event(EventCategories.Beauty, 'Clicks on Before/After', 'enabled', false);
    });
    const disableCompare = window.YMK.addEventListener(
      'compareDisabled',
      () => {
        event(
          EventCategories.Beauty,
          'Clicks on Before/After',
          'disabled',
          false
        );
      }
    );

    const closed = window.YMK.addEventListener('closed', () => {
      event(EventCategories.Beauty, 'Clicks on Restart', '/', false);
    });

    const savePhotoModalOpened = window.YMK.addEventListener(
      'savePhotoModalOpened',
      () => {
        event(EventCategories.Beauty, 'Clicks on camera', '/', false);
      }
    );

    return () => {
      window.YMK.removeEventListener(compare);
      window.YMK.removeEventListener(disableCompare);
      window.YMK.removeEventListener(closed);
      window.YMK.removeEventListener(savePhotoModalOpened);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AnimatePresence exitBeforeEnter>
      {!data ? (
        <LoaderSmall key="loader" color="white" className={styles.loader} />
      ) : (
        <motion.div
          key="interface"
          {...VARIANT_LABELS}
          variants={FADE}
          className={styles.wrapper}
        >
          {isProductSelected && (
            <>
              <ProductRevealButton
                onClick={() => {
                  event(
                    EventCategories.Beauty,
                    detailIsSelected
                      ? 'Clicks on Show less'
                      : 'Clicks on Show more',
                    type,
                    false
                  );
                  setDetailIsSelected(!detailIsSelected);
                }}
                text={
                  detailIsSelected ? (
                    <>{t('messages.show_less')}</>
                  ) : (
                    <>{t('messages.show_more')}</>
                  )
                }
                detailIsSelected={detailIsSelected}
              />
              <Product
                productName={currentProduct?.productName || ''}
                productPrice={currentProduct?.productPrice || ''}
                skuName={localeString(currentProduct?.product.sku_name) || ''}
                imageId={currentProduct?.product.sku_image?.asset._ref || ''}
                isLookItem={false}
                hideProductName={currentProduct?.hideProductName || false}
                detailIsSelected={detailIsSelected}
              />
            </>
          )}
          <ProductGroupList
            productCategories={data}
            productCategory={productCategory}
            productIsSelected={isProductSelected}
            onAddProductSku={onAddProductSku}
            selectedProducts={selectedProducts}
            detailIsSelected={detailIsSelected}
          />
          <ProductCategoryList
            productCategories={data}
            productCategory={productCategory}
            setProductCategory={setProductCategory}
            setProductIsSelected={setIsProductSelected}
            selectedProducts={selectedProducts}
          />
        </motion.div>
      )}
    </AnimatePresence>
  );
};
