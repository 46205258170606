import { Logo } from 'components';
import { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import styles from './DeviceNotice.module.css';

export const DeviceNotice: React.FC = () => {
  const { t } = useTranslation();
  const [deviceIsPortrait, setDeviceIsPortrait] = useState<boolean>(true);

  const onOrientationChange = (isPortrait: boolean | MediaQueryListEvent) => {
    if (!isPortrait) {
      setDeviceIsPortrait(false);
    } else {
      setDeviceIsPortrait(true);
    }
  };
  useEffect(() => {
    const portrait = window.matchMedia('(orientation: portrait)');

    onOrientationChange(portrait.matches);

    try {
      portrait.addEventListener('change', (event) => {
        onOrientationChange(event.matches);
      });
    } catch {
      portrait.addListener((event) => {
        onOrientationChange(event.matches);
      });
    }

    return () => {
      try {
        portrait.removeEventListener('change', onOrientationChange);
      } catch {
        portrait.removeListener(onOrientationChange);
      }
    };
  }, []);

  return (
    <>
      {!isMobile && (
        <div className={styles.wrapper}>
          <Logo className={styles.logo} />
          <div className={styles.message}>{t('messages.view_on_mobile')}</div>
        </div>
      )}
      {isMobile && !deviceIsPortrait && (
        <div className={styles.wrapper}>
          <img
            src="images/icons/rotate.png"
            alt={t('messages.view_on_mobile')}
            className={styles.rotate}
          />
        </div>
      )}
    </>
  );
};
