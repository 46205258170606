import cn from 'classnames';
import { useEffect, useRef, useState } from 'react';
import styles from './Video.module.css';

export interface VideoProps {
  feature: 'try-on' | 'shade-finder';
  filename:
    | 'intro-top'
    | 'intro-bottom'
    | 'menu-open-top'
    | 'menu-open-bottom'
    | 'menu-close-top'
    | 'menu-close-bottom';
  startPoint: 'intro' | 'menu-open' | 'menu-close';
  handleOnLoad: () => void;
  isPlaying: boolean | undefined;
  handleOnEnd: (startPoint: VideoProps['startPoint']) => void;
}

export const Video: React.FC<VideoProps> = ({
  feature,
  filename,
  startPoint,
  handleOnLoad,
  isPlaying,
  handleOnEnd,
}) => {
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [isWebMSupported, setIsWebMSupported] = useState<boolean>(false);

  const videoRef = useRef<HTMLVideoElement | null>(null);

  useEffect(() => {
    if (videoRef.current) {
      if (videoRef.current.canPlayType('video/webm; codecs="vp8, vorbis"')) {
        setIsWebMSupported(true);
      } else {
        setIsWebMSupported(false);
      }
    }
  }, []);

  const onLoad = () => {
    handleOnLoad();
    setIsLoaded(true);
  };

  const onEnd = () => {
    handleOnEnd(startPoint);
  };

  useEffect(() => {
    if (videoRef.current && isPlaying) {
      videoRef.current.play().catch(() => {
        handleOnEnd(startPoint);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPlaying, startPoint]);

  return (
    <video
      ref={videoRef}
      src={`${
        process.env.PUBLIC_URL
      }/videos/animations/${feature}/${filename}.${
        isWebMSupported ? 'webm' : 'mov'
      }`}
      muted
      playsInline
      controls={false}
      disablePictureInPicture
      preload={'auto'}
      onLoadedMetadata={!isLoaded ? onLoad : undefined}
      onEnded={onEnd}
      style={{ opacity: isPlaying ? 1 : 0 }}
      className={cn(
        styles.wrapper,
        filename === 'intro-top' && feature === 'try-on' && styles.isTopLeft,
        filename === 'intro-bottom' &&
          feature === 'try-on' &&
          styles.isBottomRight,
        filename === 'intro-top' &&
          feature === 'shade-finder' &&
          styles.isTopRight,
        filename === 'intro-bottom' &&
          feature === 'shade-finder' &&
          styles.isBottomLeft,
        filename === 'menu-open-top' && styles.isTopRight,
        filename === 'menu-open-bottom' && styles.isBottomLeft,
        filename === 'menu-close-top' && styles.isTopRight,
        filename === 'menu-close-bottom' && styles.isBottomLeft
      )}
    />
  );
};
