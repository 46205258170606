import { EnableCameraNotice, TextRotate } from 'components';
import { FADE, VARIANT_LABELS } from 'constants/animations';
import { AnimatePresence, motion, Variants } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { ExperienceType } from 'types/shared';
import styles from './LoaderLarge.module.css';

interface LoaderLargeProps {
  version: ExperienceType;
  variants: Variants;
  isCameraDisabled?: boolean;
}

export const LoaderLarge: React.FC<LoaderLargeProps> = ({
  version,
  variants,
  isCameraDisabled,
}) => {
  const { t } = useTranslation();

  return (
    <motion.div
      {...VARIANT_LABELS}
      variants={variants}
      className={styles.wrapper}
    >
      <motion.div variants={FADE} className={styles.inner}>
        <img
          className={styles.image}
          src={`${
            process.env.PUBLIC_URL
          }/images/${version.toLowerCase()}-loader.gif`}
          alt={t('messages.loading')}
        />
        <TextRotate text={t('messages.loading')} />
      </motion.div>
      <AnimatePresence>
        {isCameraDisabled && <EnableCameraNotice key="enable camera" />}
      </AnimatePresence>
    </motion.div>
  );
};
